import React, { Component } from 'react';

class Header extends Component {

  render() {
    return (
        <div className="section0" ref={this.MyRef} style={{backgroundColor: 'red', height: this.props.height}} onWheel={this.props.WheelPage} onTouchStart={this.props.touchStartHandler} onTouchMove={this.props.touchMoveHandler}>
        </div>
    );
  }
}

export default Header;